<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="500px">
    <el-form ref="form" :model="form" size="small" label-width="80px">
      <el-form-item label="用户ID">
        <el-input v-model="form.userId" style="width: 370px;" :disabled='true'/>
      </el-form-item>
      <el-form-item label="姓名/公司名称">
        <el-input v-model="form.name" style="width: 370px;" :disabled='true'/>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" style="width: 370px;" :disabled='true'/>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="form.phone" style="width: 370px;" :disabled='true'/>
      </el-form-item>
      <el-form-item label="内容">
        <el-input type="textarea"
            :rows="4" v-model="form.content" style="width: 370px;" :disabled='true'/>
      </el-form-item>
      <el-form-item label="处理状态">
        <el-select v-model="form.processingStatus" clearable placeholder="处理状态" class="filter-item" style="width: 130px">
          <el-option v-for="item in processingStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea"
            :rows="2" v-model="form.processingContent" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srCustLeavingMessage'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      processingStatusOptions:[
        {key:1,display_name:'待处理'},
        {key:2,display_name:'处理中'},
        {key:3,display_name:'已处理'},
        {key:4,display_name:'关闭'},
      ],
      form: {
        id: '',
        userId: '',
        name: '',
        email: '',
        phone: '',
        content: '',
        operatorId: '',
        processingContent: '',
        processingStatus: '',
        createTime: '',
        updateTime: ''
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        userId: '',
        name: '',
        email: '',
        phone: '',
        content: '',
        operatorId: '',
        processingContent: '',
        processingStatus: '',
        createTime: '',
        updateTime: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
